var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', [_c('Poptip', {
    attrs: {
      "slot": "title",
      "trigger": "hover",
      "title": _vm.description.title,
      "word-wrap": "",
      "width": "400",
      "content": _vm.description.desc
    },
    slot: "title"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.description.title) + " ")])]), !_vm.loading ? _c('div', {
    staticClass: "inner-container"
  }, [_c('ChartLine', {
    attrs: {
      "chartref": _vm.chartReff,
      "height": 400,
      "datasets": _vm.datasets,
      "labels": _vm.labels,
      "unit": '',
      "options": {
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: false
              },
              mode: 'y'
            }
          }
        },
        elements: {
          point: {
            radius: 3
          }
        },
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function callback(value, index, values) {
                if (value >= 52 && value < 95) {
                  return value + ' - B';
                } else if (value >= 95 && value < 110) {
                  return value + ' - C';
                }
                if (value >= 110 && value < 168) {
                  return value + ' - D';
                }
                if (value >= 168) {
                  return value + ' - E';
                } else if (value < 52 && value > 0) {
                  return value + ' - A';
                }
              }
            }
          }
        }
      },
      "colors": _vm.colors
    },
    on: {
      "clickchart": _vm.click,
      "click": _vm.click
    }
  })], 1) : [_c('Spin', {
    attrs: {
      "fix": ""
    }
  }, [_c('Icon', {
    staticClass: "demo-spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "18"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.$t('global.loading')))])], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }