
<template lang="html">
  <Card>
    <Poptip
      slot="title"
      trigger="hover"
      :title="description.title"
      word-wrap
      width="400"
      :content="description.desc"
    >
      <span v-if=" complianceData && complianceData.data && complianceData.data.length">
        {{ loading ? '...' : (description.title || complianceData.title) }}  : <Tag v-if="complianceData.data"
                                                                                    :color="alphaRiskColor[complianceData.data[0].complianceindex]"
        >
          <span :style="{color:riskColor[complianceData.data[0].complianceindex]}">
            {{ complianceData.data[0].compliancevalue }}</span></Tag>
        <span style="font-size:10px; text-transform:capitalize!important">(last calculated from : {{ moment(complianceData.data[0].created).format('LL') }})</span>
      </span>
    </Poptip>
    <template #extra>
      <WidgetResponseModal
        v-if="userHasRole('super-admin')"
        :urls="urls"
        :parameters="parameters"
        :responses="[complianceData]"
        :labels="[description.title]"
      />
    </template>
    <div>
      <div v-if="!loading && complianceData.data">
        <div v-if="complianceData.data.length">
          <ul
            class="rating"
            @click="click"
          >
            <li
              :class="{'active': complianceData.data[0].complianceindex === 'A'}"
              :description="$t('global.index.excellent')"
            >
              A
            </li>
            <li
              :class="{'active': complianceData.data[0].complianceindex === 'B'}"
              :description="$t('global.index.good')"
            >
              B
            </li>
            <li
              :class="{'active': complianceData.data[0].complianceindex === 'C'}"
              :description="$t('global.index.average')"
            >
              C
            </li>
            <li
              :class="{'active': complianceData.data[0].complianceindex === 'D'}"
              :description="$t('global.index.ngood')"
            >
              D
            </li>
            <li
              :class="{'active': complianceData.data[0].complianceindex === 'E'}"
              :description="$t('global.index.absolute')"
            >
              E
            </li>
          </ul>
        </div>
        <span v-else>{{ $t('global.no_data') }}</span>
      </div>
      <template v-else>
        <Spin fix>
          <Icon
            type="ios-loading"
            size="18"
            class="demo-spin-icon-load"
          />
          <div>{{ $t('global.loading') }}</div>
        </Spin>
      </template>
      <!-- <span v-if="!loading && !complianceData.data.length">No data</span> -->
    </div>
  </Card>
</template>

<script>

import WidgetResponseModal from '../ui/WidgetResponseModal'
import {mapGetters} from 'vuex'
import moment from 'moment'
export default {
  name: 'WidgetRisk',
  components: {WidgetResponseModal},
  props: {
    data: {
      type: Object,
      note: 'response data',
      default: () => {}
    },
    forceEndpoint: {
      type: Boolean,
      node: 'Is data from non classic endpoint',
      default: false
    },
    description: {
      type: Object,
      note: 'the widget description',
      required: true
    },
    isLoading: {
      type: Boolean,
      default: true,
      note: 'loading depending mutation'
    },
    urls: {
      type: Array,
      default: () => []
    },
    parameters: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      moment: moment,
      loading: true,
      complianceData: {},
      prettyDisplay: ''
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler: function (val, old) {
        this.complianceData = val
      }
    },
    isLoading: {
      immediate: true,
      deep: true,
      handler: function (val, old) {
        this.loading = val
      }
    }
  },
  computed: {
    ...mapGetters({
      userHasRole: 'user/hasRole',
      alphaRiskColor: 'vendor/alphaRiskColor',
      riskColor: 'vendor/riskColor'
    })
  },
  methods: {
    click () {
      if (this.$route.path !== '/compliance-index') this.$router.push('/compliance-index')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../../assets/styles/variables";

.rating {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;

  > li {
    color: #fff;
    cursor: pointer;
    flex: 1 0 auto;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight:200;
    font-size:14px;
    &:first-child {
      background: $compliance-A;
      border: 8px solid $compliance-A;
      border-radius: 16px 0 0 16px;
    }
    &:nth-child(2) {
      background: $compliance-B;
      border: 8px solid $compliance-B;
    }
    &:nth-child(3) {
      background: $compliance-C;
      border: 8px solid $compliance-C;
    }
    &:nth-child(4) {
      background: $compliance-D;
      border: 8px solid $compliance-D;
    }
    &:last-child {
      background: $compliance-E;
      border: 8px solid $compliance-E;
      border-radius: 0 16px 16px 0;
    }
    &.active {
      flex-grow: 2;
      height:80px;
      border-radius: 20px;
      font-size:40px;
      //&:after {
      //  content: attr(description);
      //  display: block;
      //  color: #707070;
      //  position:absolute;
      //  width:100%;
      //  left:0;
      //  font-size:24px;
      //  font-weight:300;
      //  margin-top: 140px;
      //  text-align: center;
      //}
    }
  }
}
</style>
