var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-tests": "widget-container"
    }
  }, [!!_vm.forceEndpoint && _vm.type === 'CHART' ? _c('ApiWidgetChart', {
    attrs: {
      "force-endpoint": !!_vm.forceEndpoint,
      "description": _vm.description,
      "data": _vm.response,
      "is-loading": _vm.isLoading
    }
  }) : _vm._e(), _vm.type === 'ONE_VALUE' ? _c('ApiWidgetOneValue', {
    attrs: {
      "force-endpoint": !!_vm.forceEndpoint,
      "description": _vm.description,
      "is-loading": _vm.isLoading,
      "data": _vm.response,
      "data2": _vm.secondResponse,
      "urls": [_vm.url, _vm.secondUrl],
      "parameters": [_vm.parameters, _vm.secondParameters]
    },
    on: {
      "click": _vm.showDrawer
    }
  }) : _vm._e(), _vm.type === 'RISK' ? _c('ApiWidgetRisk', {
    attrs: {
      "force-endpoint": !!_vm.forceEndpoint,
      "description": _vm.description,
      "is-loading": _vm.isLoading,
      "data": _vm.response,
      "urls": [_vm.url, _vm.secondUrl],
      "parameters": [_vm.parameters, _vm.secondParameters]
    }
  }) : _vm._e(), _vm.type === 'LINE' ? _c('ApiComplianceChartLine', {
    attrs: {
      "chart-ref": _vm._uid,
      "description": _vm.description,
      "is-loading": _vm.isLoading,
      "data": _vm.response,
      "urls": [_vm.url],
      "parameters": [_vm.parameters]
    },
    on: {
      "click": function click() {}
    }
  }) : _vm._e(), _c('ApiWidgetFocus', {
    attrs: {
      "show": _vm.scopeFocus,
      "excluded-filters": _vm.excludedFilters,
      "api": _vm.api,
      "namespace": _vm.namespace,
      "description": _vm.description,
      "params": Object.assign({}, _vm.params, {
        properties: [_vm.scopeSelectedProperty.id],
        period: _vm.scopeSelectedDaterange
      })
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }