
<template>
  <Card style="height:100%;">
    <Poptip
      slot="title"
      trigger="hover"
      :title="data.title1 || description.title"
      word-wrap
      width="400"
      :content="data.title1 || description.desc"
    >
      <span>{{ loading ? '...' : (data.title1 ? data.title1 : description.title) }}</span>
    </Poptip>
    <template #extra>
      <WidgetResponseModal
        v-if="userHasRole('super-admin')"
        :urls="urls"
        :parameters="parameters"
        :responses="[data, data2]"
        :labels="[description.title, description.title2]"
      />
    </template>
    <div>
      <div v-if="!loading">
        <a
          class="big-number big-number--action"
          @click="click"
        >
          <!-- <span style="font-size:14px;width:100%;display:block"> {{ data.desc1 }}</span> -->
          {{ data.v1 || (data.pagination ? data.pagination.count : 0) || data.value || 0 }}
          
        </a>
        <span
          v-if="data2 || data.v2"
          style="width:100%;display:block;text-align:center"
        >{{ data.v2 || (data2.pagination ? data2.pagination.count : 0) }} {{ data.desc2 || description.title2 }}</span>
        <span
          v-if="data.v3"
          style="width:100%;display:block;text-align:center"
        >{{ data.v3 }} {{ data.desc3 }}</span>
      </div>
      
      <template v-else>
        <Spin fix>
          <Icon
            type="ios-loading"
            size="18"
            class="demo-spin-icon-load"
          />
          <div>{{ $t('global.loading') }}</div>
        </Spin>
      </template>
    </div>
  </Card>
</template>

<script>
import { mapGetters } from 'vuex'
import WidgetResponseModal from '../ui/WidgetResponseModal'

export default {
  name: 'WidgetOneValue',
  components: {WidgetResponseModal},
  props: {
    data: {
      type: Object,
      note: 'response data',
      default: () => {}
    },
    data2: {
      type: [Object, Boolean],
      note: 'response data for two value',
      default: false
    },
    description: {
      type: Object,
      note: 'the widget description',
      required: true
    },
    isLoading: {
      type: Boolean,
      default: true,
      note: 'loading depending mutation'
    },
    urls: {
      type: Array,
      default: () => []
    },
    parameters: {
      type: Array,
      default: () => []
    },
    forceEndpoint: {
      type: Boolean,
      note: 'If data came from forced url',
      default: false
    }
  },
  data: () => {
    return {
      loading: true,
      prettyDisplay: ''
    }
  },
  computed: {
    ...mapGetters({
      userHasRole: 'user/hasRole',
    })
  },
  watch: {
    isLoading: {
      immediate: true,
      handler: function (val, old) {
        this.$nextTick()
          .then(() => {
            this.loading = val
          })
      }
    }
  },
  methods: {
    click () {
      this.$emit('click', true)
    }
  }
}
</script>

<style lang="scss">

.big-number {
  font-weight: 100;
  font-size: 80px;
  word-break: break-word;
  text-align: center;
  color: #8A9190;
  display: block;
  line-height: 1;

  &.big-number--action {
    color: #1CA08D;
  }
}
</style>
