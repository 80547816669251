<template>
  <Card>
    <Poptip
      slot="title"
      trigger="hover"
      :title="description.title"
      word-wrap
      width="400"
      :content="description.desc"
    >
      <p>
        {{ description.title }}
      </p>
    </Poptip>
    <div class="inner-container"
         v-if="!loading"
    >
      <ChartLine
        :chartref="chartReff"
        :height="400"
        :datasets="datasets"
        :labels="labels"
        @clickchart="click"
        :unit="''"
        :options="{
          plugins: {
            zoom: {
              zoom: {
                wheel: {
                  enabled: false,
                },
                mode: 'y',
              }
            },
          },
          elements: { point: { radius: 3 } },
          scales: {
            
            y: {
              ticks: {
                // Include a dollar sign in the ticks
                callback: function(value, index, values) {
                  if (value >= 52 && value < 95) {
                    return value + ' - B'
                  } else if (value >= 95 && value < 110) {
                    return value + ' - C'
                  }if (value >= 110 && value < 168) {
                    return value + ' - D'
                  }
                  if (value >= 168) {
                    return value + ' - E'
                  } else if (value < 52 && value > 0){
                    return value + ' - A'
                  }
                  
                }
              }
            }
          }
        }"
        :colors="colors"
        @click="click"
      />
    </div>
    <template v-else>
      <Spin fix>
        <Icon
          type="ios-loading"
          size="18"
          class="demo-spin-icon-load"
        />
        <div>{{ $t('global.loading') }}</div>
      </Spin>
    </template>
  </Card>
</template>
<script>
import ChartLine from '../chart/ChartLine.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import {eventBus} from '@/main'
export default {
  name: 'APiBasedLineChart',
  components: {
    ChartLine
  },
  data () {
    return {
      loading: true,
      moment: moment,
      chartReff: this.chartRef
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler: function (val, old) {
        this.$nextTick()
          .then(() => {
            if (Object.keys(val)) {
              this.loading = false
            }
          })
      }
    }
  },
  props: {
    chartRef: {
      type: Number,
      note: 'parent uid',
      required: true
    },
    data: {
      type: Object,
      note: 'response data',
      default: () => {}
    },
    description: {
      type: Object,
      note: 'the widget description',
      required: true
    },
    isLoading: {
      type: Boolean,
      default: true,
      note: 'loading depending mutation'
    },
    urls: {
      type: Array,
      default: () => []
    },
    parameters: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      riskColor: 'vendor/riskColor'
    }),
    datasets () {
      const {data} = this
      try {
        const values = data.data.map(elm => elm.compliancevalue)
        return [{
          data: values,
          borderColor: '#ff0',
          fill:true,
          backgroundColor: '',
          label: 'Compliance value'
        },]
      } catch (er) {
        return []
      }
      

    },
    labels () {
      const { data } = this
      try {
        const days = data.data.map(elm => moment(elm.date).format('LL'))
        return days
      } catch (er) {
        return []
      }
    },
    colors () {
      const { data } = this
      try {
        const colors = data.data.map(elm => {
          return '#A6A998'
        })
        return colors
      }
      catch (er) {
        return []
      }
    },
  },
  methods: {
    click (val) {
      const clickedData = this.data.data.find(elm => moment(elm.date).format('LL') === val.label)
      eventBus.$emit('open-detail-from-chart', clickedData)
    }
  }
}
</script>
