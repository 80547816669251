<template>
  <Card style="height:100%">
    <span slot="title">
      {{ loading ? '...' : data.title1 }}
    </span>
    <div style="width:100%;max-width:400px; margin: 0 auto">
      <canvas :id="`chart_${_uid}`" />
    </div>
  </Card>
</template>
<script>
import Chart from 'chart.js/auto'

export default {
  name: 'BasicApiWidgetChart',
  props: {
    data: {
      type: Object,
      note: 'The object data',
      required: true
    },
    isLoading: {
      type: Boolean,
      default: true,
      note: 'loading depending mutation'
    },
  },
  watch: {
    isLoading: {
      immediate: true,
      handler: function (val, old) {
        this.$nextTick()
          .then(() => {
            this.loading = val
            if (val === false) {
              this.renderChart()
            }
          })
      }
    }
  },
  data() {
    return {
      chartCanvas: null,
      loading: true,
      chartConfig: null,
      config: {
        line: {
          type: 'line',
          data: null
        },
        polarArea: {
          type: 'polarArea',
          data: null
        },
        doughnut: {
          type: 'doughnut',
          data: null
        },
        pie: {
          type: 'pie',
          data: null
        },
        bar: {
          type: 'bar',
          data: null,
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          },
        }
      }
    }
  },
  methods: {
    renderChart () {
      const {data} = this
      const ctx = document.getElementById('chart_' + this._uid)
      this.chartConfig = this.config[data.chartType]
      this.chartConfig.data = data.chart
      new Chart(ctx, this.chartConfig)
    }
  }
}
</script>