<template>
  <div data-tests="widget-container">
    <ApiWidgetChart
      v-if="!!forceEndpoint && type === 'CHART'"
      :force-endpoint="!!forceEndpoint"
      :description="description"
      :data="response"
      :is-loading="isLoading"
    />
    <ApiWidgetOneValue
      v-if="type === 'ONE_VALUE'"
      :force-endpoint="!!forceEndpoint"
      :description="description"
      :is-loading="isLoading"
      @click="showDrawer"
      :data="response"
      :data2="secondResponse"
      :urls="[url, secondUrl]"
      :parameters="[parameters, secondParameters]"
    />
    <ApiWidgetRisk
      v-if="type === 'RISK'"
      :force-endpoint="!!forceEndpoint"
      :description="description"
      :is-loading="isLoading"
      :data="response"
      :urls="[url, secondUrl]"
      :parameters="[parameters, secondParameters]"
    />
    <ApiComplianceChartLine
      v-if="type === 'LINE'"
      :chart-ref="_uid"
      :description="description"
      :is-loading="isLoading"
      @click="() => {}"
      :data="response"
      :urls="[url]"
      :parameters="[parameters]"
    />
    <ApiWidgetFocus
      :show="scopeFocus"
      :excluded-filters="excludedFilters"
      :api="api"
      :namespace="namespace"
      :description="description"
      :params="{...params,properties: [scopeSelectedProperty.id],period: scopeSelectedDaterange}"
    />
  </div>
</template>
<script>
import ApiWidgetOneValue from '@/components/widgets/ApiWidgetOneValue'
import ApiWidgetRisk from '@/components/widgets/ApiWidgetRisk'
import ApiWidgetFocus from '@/components/ui/ApiWidgetFocus'
import ApiComplianceChartLine from '@/components/widgets/ApiComplianceChartLine'
import ApiWidgetChart from '@/components/widgets/ApiWidgetChart'
import { mapActions, mapGetters } from 'vuex'
import { api as env } from '@/config.js'
import axios from 'axios'
export default {
  name: 'ApiBasedCreatorWidget',
  components: { ApiWidgetOneValue, ApiWidgetFocus, ApiWidgetRisk, ApiComplianceChartLine, ApiWidgetChart },
  props: {
    customClickFunction: {
      type: [Boolean, Object],
      default: false,
      note: 'custom action when clicked on the widget'
    },
    excludedFilters: {
      type: Array,
      default: () => []
    },
    params: {
      type: Object,
      default: () => {}
    },
    redirect: {
      type: [String, Boolean, Array],
      note: 'specific redirection if needed',
      default: false,
    },
    secondParams: {
      type: [Object, Boolean],
      default: false
    },
    description: {
      type: Object,
      note: 'the widget description',
      default: () => ({title:'', title2:'', desc: ''})
    },
    type: {
      type: String,
      note: 'Widget type',
      default: ''
    },
    namespace: {
      type: String,
      default: ''
    },
    specialCall: {
      type: [String, Boolean],
      note: 'if a special api url is given',
      default: false
    },
    forceEndpoint: {
      type: [String, Boolean],
      note: 'Force non vue endpoint',
      default: false
    }
  },
  data: () => {
    return {
      isLoading: true,
      openDrawer: false,
      api: {
        cookie: 'getCookies',
        vendor: 'getVendors'
      },
      response: {},
      secondResponse: false,
      url: '',
      secondUrl: '',
      parameters: {},
      secondParameters: {}
    }
  },
  watch: {
    scopeQs: {
      handler: function (val, old) {
        if (val !== old) {
          this.fetch()
          this.secondParams && this.fetchSecondValue(this.secondParams)
          if (this.forceEndpoint) {
            return this.fetchGivenUrl(this.forceEndpoint)
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      scopeSelectedDaterange: 'scope/selectedDaterange',
      scopeFocus: 'scope/focus',
      scopeSelectedProperty: 'scope/selectedProperty',
      scopeQs: 'scope/qs',
      hash: 'hash'
    })
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (!this.namespace) return false
      const mutationName = this.namespace + '/GET_' + this.namespace.toUpperCase() + '_PENDING'
      const idCall = this._uid
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === mutationName && mutation.payload.id === idCall) {
          this.isLoading = mutation.payload.state
        }
      })
    })
  },
  mounted () {
    if (this.forceEndpoint) {
      return this.fetchGivenUrl(this.forceEndpoint)
    }
    this.fetch()
    this.secondParams && this.fetchSecondValue(this.secondParams)
  },
  methods: {
    ...mapActions({
      setFocus: 'scope/setFocus'
    }),
    fetchGivenUrl (url) {
      this.isLoading = true
      this.parameters = this.params
      return this.$axios(url, {params: this.parameters})
        .then(r => {
          if (r.data.widget){
            this.response = r.data.widget
          }else if (r.data.data){
            this.response = r.data.data
          }

          this.$nextTick(() => {this.isLoading = false})
        })
    },
    fetch () {
      if (this.forceEndpoint) return
      const { params, namespace, scopeSelectedProperty, scopeSelectedDaterange, api } = this
      const actionName = this.specialCall
        ? namespace + '/' + this.specialCall
        : namespace + '/' + api[namespace]

      if (this.specialCall) {
        this.parameters = params
      } else {
        this.parameters = {
          ...params,
          properties: [scopeSelectedProperty.id],
          period: scopeSelectedDaterange
        }
      }

      this.url = `${env.root}api/${namespace}`
      console.log({actionName, parameters: this.parameters})
      return this.$store.dispatch(actionName, {
        params: this.parameters,
        uid: this._uid
      }).then(r => {
        this.response = r
      })
    },
    fetchSecondValue () {
      const { secondParams, namespace, scopeSelectedProperty, scopeSelectedDaterange, api } = this
      const actionName = this.specialCall
        ? namespace + '/' + this.specialCall
        : namespace + '/' + api[namespace]

      this.secondParameters = {
        ...secondParams,
        properties: [scopeSelectedProperty.id],
        period: scopeSelectedDaterange
      }
      this.secondUrl = `${env.root}api/${namespace}`
      return this.$store.dispatch(actionName, {
        params: this.secondParameters,
        uid: this._uid
      }).then(r => {
        this.secondResponse = r
      })
    },
    showDrawer () {
      if (this.redirect && this.redirect.length) {
        if (Array.isArray(this.redirect)) {
          return this.$router.push({ path: this.redirect[0], hash: this.redirect[1] })
        }
        return this.$router.push(this.redirect)
      }
      if (this.customClickFunction) {
        return this.customClickFunction.method(this.response.link)
      }
      const stringify = JSON.stringify({...this.params, properties: [this.scopeSelectedProperty.id], period: this.scopeSelectedDaterange}) + this.namespace
      // Generate key from params to identify which drawer should be active (will be used on mutation watcher)
      const id = this.hash(stringify)
      this.setFocus(id)
    }
  }
}
</script>
