var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    scopedSlots: _vm._u([{
      key: "extra",
      fn: function fn() {
        return [_vm.userHasRole('super-admin') ? _c('WidgetResponseModal', {
          attrs: {
            "urls": _vm.urls,
            "parameters": _vm.parameters,
            "responses": [_vm.complianceData],
            "labels": [_vm.description.title]
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('Poptip', {
    attrs: {
      "slot": "title",
      "trigger": "hover",
      "title": _vm.description.title,
      "word-wrap": "",
      "width": "400",
      "content": _vm.description.desc
    },
    slot: "title"
  }, [_vm.complianceData && _vm.complianceData.data && _vm.complianceData.data.length ? _c('span', [_vm._v(" " + _vm._s(_vm.loading ? '...' : _vm.description.title || _vm.complianceData.title) + " : "), _vm.complianceData.data ? _c('Tag', {
    attrs: {
      "color": _vm.alphaRiskColor[_vm.complianceData.data[0].complianceindex]
    }
  }, [_c('span', {
    style: {
      color: _vm.riskColor[_vm.complianceData.data[0].complianceindex]
    }
  }, [_vm._v(" " + _vm._s(_vm.complianceData.data[0].compliancevalue))])]) : _vm._e(), _c('span', {
    staticStyle: {
      "font-size": "10px",
      "text-transform": "capitalize!important"
    }
  }, [_vm._v("(last calculated from : " + _vm._s(_vm.moment(_vm.complianceData.data[0].created).format('LL')) + ")")])], 1) : _vm._e()]), _c('div', [!_vm.loading && _vm.complianceData.data ? _c('div', [_vm.complianceData.data.length ? _c('div', [_c('ul', {
    staticClass: "rating",
    on: {
      "click": _vm.click
    }
  }, [_c('li', {
    class: {
      'active': _vm.complianceData.data[0].complianceindex === 'A'
    },
    attrs: {
      "description": _vm.$t('global.index.excellent')
    }
  }, [_vm._v(" A ")]), _c('li', {
    class: {
      'active': _vm.complianceData.data[0].complianceindex === 'B'
    },
    attrs: {
      "description": _vm.$t('global.index.good')
    }
  }, [_vm._v(" B ")]), _c('li', {
    class: {
      'active': _vm.complianceData.data[0].complianceindex === 'C'
    },
    attrs: {
      "description": _vm.$t('global.index.average')
    }
  }, [_vm._v(" C ")]), _c('li', {
    class: {
      'active': _vm.complianceData.data[0].complianceindex === 'D'
    },
    attrs: {
      "description": _vm.$t('global.index.ngood')
    }
  }, [_vm._v(" D ")]), _c('li', {
    class: {
      'active': _vm.complianceData.data[0].complianceindex === 'E'
    },
    attrs: {
      "description": _vm.$t('global.index.absolute')
    }
  }, [_vm._v(" E ")])])]) : _c('span', [_vm._v(_vm._s(_vm.$t('global.no_data')))])]) : [_c('Spin', {
    attrs: {
      "fix": ""
    }
  }, [_c('Icon', {
    staticClass: "demo-spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "18"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.$t('global.loading')))])], 1)]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }