var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', {
    staticStyle: {
      "height": "100%"
    }
  }, [_c('span', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.loading ? '...' : _vm.data.title1) + " ")]), _c('div', {
    staticStyle: {
      "width": "100%",
      "max-width": "400px",
      "margin": "0 auto"
    }
  }, [_c('canvas', {
    attrs: {
      "id": "chart_".concat(_vm._uid)
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }